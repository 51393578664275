//
//
//
//
//
//
//
//

export default {
		name: 'navigationFooter',
		computed: { 
			menu() {
				return this.$store.state.global.content.navigationFooter;
			}
		},
	}

