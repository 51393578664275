//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NavigationOffscreen from '../components/nav/navigationOffscreen.vue'

	import { mapState } from 'vuex'
	import { mapMutations } from 'vuex'
import NavigationDefault from '../components/nav/navigationDefault.vue'
import NavigationFooter from '../components/nav/navigationFooter.vue'

const apiKey = process.env.GMAP_API_KEY;

	export default {
    head() {
      return {
        script: [
          {src: `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`}
        ]
      };
    },
    name: "layoutDefault",
    methods: {
        ...mapMutations({
            setOffscreenState: "global/setOffscreenState"
        })
    },  
    mounted() {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://api.feefo.com/api/javascript/first-rate-maintenance-ltd";
      document.getElementById("footer-scripts").appendChild(script);

    
    },
    computed: {
        ...mapState({
            navigationFooter(state) {
                return state.global.content.navigationFooter;
            },
            navigationMain(state) {
                return state.global.content.navigationMain;
            },
            offscreenState(state) {
                return state.global.offscreenState;
            },
            facebook_url(state) {
                return state.global.content.facebook_url;
            },
            twitter_url(state) {
                return state.global.content.twitter_url;
            },
            instagram_url(state) {
                return state.global.content.instagram_url;
            },
            footer_address(state) {
                return state.global.content.footer_address;
            },
            map_link(state) {
                return state.global.content.map_link;
            },
            email(state) {
                return state.global.content.email;
            },
            phone(state) {
                return state.global.content.phone;
            },
            services_list(state) {
                return state.global.content.services_list;
            }
        })
    },
    async fetch() {
        if (process.server) {
            await this.$axios.$post("api/query", {
                query: "site",
                select: {
                    title: "site.title",
                    categories: "site.categories",
				          	footer_address: "site.footer_address",
				          	map_link: "site.map_link",
                    globaltags: "site.globaltags",
                    facebook_url: "site.facebook_url",
                    twitter_url: "site.twitter_url",
                    instagram_url: "site.instagram_url",
                    youtube_url: "site.youtube_url",
                    navigationMain: "site.navigation_main.toNavigationArray",
                    navigationFooter: "site.navigation_footer.toNavigationArray",
                    services_list: "site.footer_services.toStructure",
                    email: "site.email",
                    phone: "site.phone",
                }
            }).then(resp => {
                this.$store.commit("global/setGlobalContent", resp.result);
            }).catch(e => {
                console.log("navigation bar error", e);
            });
        }
    },
    components: { NavigationDefault, NavigationFooter, NavigationOffscreen }
}

