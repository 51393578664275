//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapMutations } from 'vuex'
export default {
	name: 'navigationOffscreen',
	props: {
		menu: Array
	},
	methods: {
		...mapMutations({
			setOffscreenState: 'global/setOffscreenState'
		})
	},
}



